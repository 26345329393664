/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import ToastService from "../common/services/toast-service";
import SdkSettingsService, { FiltersData, SdkSetting } from "./SdkSettingsService";
import SdkSettingsForm from "./SdkSettingsForm";
import SdkSettingsTable from "./SdkSettingsTable";

export interface IFilters {
    apiKey: string;
    deviceOS: string;
}

const SdkSettings = () => {
    const [filters, setFilters] = useState<FiltersData>({ apiKey: [], deviceOS: [] });
    const [filtersToQuery, setFiltersToQuery] = useState<IFilters>({ apiKey: "", deviceOS: "All" });
    const [data, setData] = useState<SdkSetting[]>([]);

    const getData = async () => {
        try {
            const data = await SdkSettingsService.queryData(filtersToQuery.apiKey, filtersToQuery.deviceOS);
            setData(data);
        } catch (error: any) {
            ToastService.showToast("Error getting queried data", error.message);
        }
    }

    const getFilters = async () => {
        try {
            const data = await SdkSettingsService.getFilters();
            setFilters(data);
        } catch (error: any) {
            ToastService.showToast("Error getting filters", error.message);
        }
    }

    useEffect(() => {
        getFilters();
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [filtersToQuery]);

    const onSettingUpdate = async (setting: SdkSetting) => {
        try {
            await SdkSettingsService.updateSetting(setting);
            await getData();
            ToastService.showToast(setting.key, "Setting updated successfully", true);
        } catch (error: any) {
            ToastService.showToast("Error updating setting", error.message);
        }
    }
    
    return (
        <Container>
            <Container>
                <SdkSettingsForm
                    apiKeysList={filters.apiKey}
                    deviceOsList={filters.deviceOS}
                    currentApiKey={filtersToQuery.apiKey}
                    currentDeviceOS={filtersToQuery.deviceOS}
                    onApiKeyChange={(value) => setFiltersToQuery(s => ({
                        ...filtersToQuery,
                        apiKey: value
                    }))}
                    onDeviceOSChange={(value) => setFiltersToQuery(s => ({
                        ...filtersToQuery,
                        deviceOS: value
                    }))}
                />
            </Container>
            {data ? <SdkSettingsTable settings={data} onSettingUpdate={onSettingUpdate} /> : <Spinner color="primary" />}
        </Container>
    )
};

export default SdkSettings;

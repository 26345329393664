import { createContext, FC, useState } from "react";
import { useLocation } from "react-router-dom";
import authenticator from "../authentication/authenticator";

interface IAuthenticationContext {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
};

export const AuthContext = createContext<IAuthenticationContext>({} as IAuthenticationContext);

const AuthenticationProvider: FC = ({ children }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const tokenRenewalCallback = async () => {
    await authenticator.signinSilent();
  };

  const authenticationEstablishedCallback = () => setIsAuthenticated(true);

  const hasAuthParams = async () => {
    const searchParams = new URLSearchParams(location.search);

    if ((searchParams.has("code") || searchParams.has("error")) && searchParams.has("state")) {
      const storedState = await authenticator.settings.stateStore?.get(searchParams.get("state")!);

      if (storedState) {
        return true;
      }
    }

    return false;
  }

  const finishLogin = async (): Promise<void> => {
    authenticator.events.addUserLoaded(authenticationEstablishedCallback);
    authenticator.events.addAccessTokenExpired(tokenRenewalCallback);

    await authenticator.signinRedirectCallback();
    await authenticator.clearStaleState();
  };

  const login = async () => {
    // if there's state in the url, it means it's a callback and the login should be completed
    if (await hasAuthParams()) {
      await finishLogin();
      return;
    }
    
    await authenticator.signinRedirect();
  };

  const logout = async () => {
    authenticator.events.removeUserLoaded(authenticationEstablishedCallback);
    authenticator.events.removeAccessTokenExpired(tokenRenewalCallback);

    await authenticator.signoutRedirect({
      post_logout_redirect_uri: window.location.href,
    });
  };

  return (
    <AuthContext.Provider value={{
      login,
      logout,
      isAuthenticated
      }}
    >
      { children }
    </AuthContext.Provider>
  )
};

export default AuthenticationProvider;
import { useState } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

export type FormType = "text" | "date" | "number" | "checkbox";

export interface FormFieldProps {
    label: string
    type: FormType
    required?: boolean
    initialValue?: string
    isValid?: () => boolean
    onChange: (val: string|undefined) => void
}

export const FormField = (props: FormFieldProps) => {
    const [touched, setTouched] = useState<boolean>(false);

    const onChange = (newValue: string) => {
        setTouched(true)
        props.onChange(newValue)
    }

    return (
        <FormGroup>
            <Label for={props.label}>{props.label}</Label>
        <Input
            type={props.type}
            name={props.label}
            defaultValue={props.initialValue}
            invalid={props.isValid !== undefined && touched && !props.isValid()}
            onChange={e => {onChange(e.target.value)}}
        ></Input>
        {props.required &&
            <FormFeedback>This field is required</FormFeedback>
        }
    </FormGroup>
    );
}